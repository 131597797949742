import type {FC} from 'react';
import clsx from 'clsx';

import ImageFill from '@/components/image/ImageFill';
import type {ImageWeb} from '@/components/image/types';
import {Link} from '@/components/link';
import {Paragraph} from '@/components/typography/paragraph';

interface Props {
	href: string;
	title: string;
	image?: ImageWeb;
}

const LinkCard: FC<Props> = ({image, href, title}) => {
	return (
		<div className={clsx('flex', 'flex-col', 'border')}>
			<div className={clsx('relative', 'h-80')}>
				{image && (
					<ImageFill
						src={image.image.src}
						width={image.image.width}
						height={image.image.height}
						hotspot={image.image.hotspot}
						alt={image.altText ?? ''}
					/>
				)}
			</div>
			<Paragraph asChild>
				<Link href={href}>{title}</Link>
			</Paragraph>
		</div>
	);
};

export default LinkCard;

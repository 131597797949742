import type {FC, PropsWithChildren} from 'react';
import React from 'react';
import clsx from 'clsx';

import {Container} from '@/components/container';
import ImageFill from '@/components/image/ImageFill';
import type {ImageWeb} from '@/components/image/types';
import {Heading} from '@/components/typography/heading';
import type {PortableTextOverrides} from '@/sanity/components/portable-text';

type Props = PropsWithChildren & {
	imageWeb?: ImageWeb;
	heading?: string;
};

export const heroWithBgImagePortableTextOverrides: PortableTextOverrides = {
	callToActionLine: 'justify-center',
};

export const HeroWithBgImage: FC<Props> = ({children, imageWeb, heading}) => {
	return (
		<Container>
			{imageWeb && (
				<div className={clsx('-z-10')}>
					<div
						className={clsx(
							'absolute',
							' inset-0',
							'z-10',
							'bg-secondary-dark',
							'mix-blend-overlay',
						)}
					/>
					<ImageFill
						priority // Images in page heads are usually part of the LCP
						src={imageWeb.image.src}
						width={imageWeb.image.width}
						height={imageWeb.image.height}
						hotspot={imageWeb.image.hotspot}
						alt={imageWeb.altText ?? ''}
						className={clsx('saturate-0', 'opacity-10')}
					/>
				</div>
			)}
			<div className={clsx('mx-auto', 'max-w-3xl', 'text-center', 'py-64', 'text-white')}>
				{heading && <Heading spacing>{heading}</Heading>}

				{children}
			</div>
		</Container>
	);
};

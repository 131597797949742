import type {FC} from 'react';

import ProductList from '../../components/product-list';

import {type ProductListAllFragment, productListFromProductListAllSection} from './query';

type Props = {
	data: ProductListAllFragment;
};

export const ProductListAllContainer: FC<Props> = ({data}) => {
	const products = productListFromProductListAllSection(data);

	return (
		<ProductList
			products={products}
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
		/>
	);
};

import type {FC} from 'react';
import clsx from 'clsx';

import type {ContainerSpacing} from '@/components/container';
import {Container} from '@/components/container';
import {Heading} from '@/components/typography/heading';
import {Paragraph} from '@/components/typography/paragraph';
import type {KeyedArray} from '@/shared/types';

import type {ProductDetails} from '../../shared/types';
import {ProductCard} from '../product-card';

interface Props {
	title?: string;
	tagline?: string;
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	products: KeyedArray<ProductDetails>;
}

const ProductList: FC<Props> = ({title, tagline, spacingBottom, spacingTop, products}) => {
	return (
		<Container spacingBottom={spacingBottom} spacingTop={spacingTop}>
			{title && (
				<Heading level={2} spacing>
					{title}
				</Heading>
			)}
			{tagline && <Paragraph>{tagline}</Paragraph>}

			<ul className={clsx('grid', 'auto-rows-fr', 'grid-cols-1', 'lg:grid-cols-3', 'gap-8')}>
				{products && (
					<>
						{products.map((products) => {
							return (
								<li key={products._key}>
									<ProductCard product={products} headingLevel={title ? 3 : 2} />
								</li>
							);
						})}
					</>
				)}
			</ul>
		</Container>
	);
};
export default ProductList;

import type {FC} from 'react';

import {ArticleListAllContainer} from '@/extensions/article/sanity/article-list-all-container';
import {ArticleListSelectionContainer} from '@/extensions/article/sanity/article-list-selection-container';
import {ContactFormSectionContainer} from '@/extensions/contact-form/sanity/contact-form-section-container';
import {PersonListAllContainer} from '@/extensions/person/sanity/person-list-all-container';
import {PersonListGroupContainer} from '@/extensions/person/sanity/person-list-group-container';
import {PersonListSelectionContainer} from '@/extensions/person/sanity/person-list-selection-container';
import {ProductListAllContainer} from '@/extensions/product/sanity/product-list-all-container';
import {ProductListSelectionContainer} from '@/extensions/product/sanity/product-list-selection-container';
import type {SanityArray} from '@/sanity/lib/types';

import {GalleryContainer} from './gallery-container';
import {LinkListSelectionContainer} from './link-list-selection-container';
import type {ContentBlockFragment} from './query';
import {TextContainer} from './text-container';
import {TextWithImageSplitContainer} from './text-with-image-split-container';

interface Props {
	content: SanityArray<ContentBlockFragment>;
}

const ContentBlocks: FC<Props> = ({content}) => {
	const sections = content?.map((section) => {
		switch (section._type) {
			case 'textSection':
				return <TextContainer key={section._key} data={section} />;
			case 'textWithImageSplit':
				return <TextWithImageSplitContainer key={section._key} data={section} />;
			case 'gallery':
				return <GalleryContainer key={section._key} data={section} />;
			case 'linkListSelection':
				return <LinkListSelectionContainer key={section._key} data={section} />;
			case 'articleListSelection':
				return <ArticleListSelectionContainer key={section._key} data={section} />;
			case 'articleListAll':
				return <ArticleListAllContainer key={section._key} data={section} />;
			case 'personListSelection':
				return <PersonListSelectionContainer key={section._key} data={section} />;
			case 'personListAll':
				return <PersonListAllContainer key={section._key} data={section} />;
			case 'personListGroup':
				return <PersonListGroupContainer key={section._key} data={section} />;
			case 'contactFormSection':
				return <ContactFormSectionContainer key={section._key} data={section} />;
			case 'productListAll':
				return <ProductListAllContainer key={section._key} data={section} />;
			case 'productListSelection':
				return <ProductListSelectionContainer key={section._key} data={section} />;
			default:
				return null;
		}
	});

	return <>{sections}</>;
};

export default ContentBlocks;

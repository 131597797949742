import type {FC, ReactNode} from 'react';
import clsx from 'clsx';
import Link from 'next/link';

import {ImageBlock} from '@/components/image/ImageBlock';
import {Heading} from '@/components/typography/heading';
import {Paragraph} from '@/components/typography/paragraph';
import {formatPrice} from '@/shared';

import type {ProductDetails} from '../../shared/types';

type Props = {
	product: ProductDetails;
	headingLevel: 2 | 3;
};

export const ProductCard: FC<Props> = ({product, headingLevel}) => {
	const {title, description, price, href, imageWeb} = product;

	return (
		<div
			className={clsx(
				'relative',
				'rounded-xl',
				'overflow-hidden',
				'bg-white',
				'shadow-[0px_0px_8px_rgba(0,0,0,0.05)]',
				'isolate',
			)}
		>
			{imageWeb && (
				<ImageBlock
					height={imageWeb.image.height}
					src={imageWeb.image.src}
					width={imageWeb.image.width}
					alt={imageWeb.altText ?? ''}
				/>
			)}

			<div
				className={clsx(
					'text-center',

					'p-4',
				)}
			>
				<Heading level={headingLevel} size="medium">
					<CardLink href={href}>{title}</CardLink>
				</Heading>

				<Paragraph>kr. {formatPrice(price ?? 0)}</Paragraph>

				{description && <Paragraph>{description}</Paragraph>}
			</div>
		</div>
	);
};

const CardLink: FC<{href: string; children: ReactNode}> = ({href, children}) => {
	return (
		<Link href={href} className={clsx('after:absolute', 'after:inset-0')}>
			{children}
		</Link>
	);
};

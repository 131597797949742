import {groq} from 'next-sanity';

import type {KeyedArray} from '@/shared/types';

import type {ProductDetails} from '../../shared/types';
import type {ProductInCardFragment} from '../query';
import {PRODUCT_IN_CARD_FRAGMENT, productInCardFragmentToProductDetails} from '../query';
import {product} from '../schema';

import type {ProductListAllSchema} from './schema';

export const PRODUCT_LIST_ALL_FRAGMENT = groq`
	...,
	orderBy == "publishDateNewestFirst" => {
		"products": *[_type == "${product().name}" && !isArchived] | order(_createdAt desc) {
			${PRODUCT_IN_CARD_FRAGMENT}
		}
	},
	orderBy == "publishDateOldestFirst" => {
		"products": *[_type == "${product().name}" && !isArchived] | order(_createdAt asc) {
			${PRODUCT_IN_CARD_FRAGMENT}
		}
	},
	orderBy == "titleAtoZ" => {
		"products": *[_type == "${product().name}" && !isArchived] | order(lower(title) asc) {
			${PRODUCT_IN_CARD_FRAGMENT}
		}
	},
	orderBy == "titleZtoA" => {
		"products": *[_type == "${product().name}" && !isArchived] | order(lower(title) desc) {
			${PRODUCT_IN_CARD_FRAGMENT}
		}
	},
	!defined(orderBy) => {
		"products": *[_type == "${product().name}" && !isArchived] | order(_createdAt desc) {
			${PRODUCT_IN_CARD_FRAGMENT}
		}
	}
`;

export type ProductListAllFragment = ProductListAllSchema & {
	_type: 'productListAll';
	products: ProductInCardFragment[];
};

export const productListFromProductListAllSection = (
	section: ProductListAllFragment,
): KeyedArray<ProductDetails> => {
	const products: KeyedArray<ProductDetails> = [];

	if (!section.products?.length) {
		return products;
	}

	for (const productInCard of section.products) {
		if (productInCard) {
			const product = productInCardFragmentToProductDetails(productInCard);
			if (product) {
				products.push({
					_key: productInCard._id,
					...product,
				});
			}
		}
	}

	return products;
};

import type {FC, PropsWithChildren} from 'react';
import clsx from 'clsx';

import ImageFill from '@/components/image/ImageFill';
import type {ImageWeb} from '@/components/image/types';
import {Heading} from '@/components/typography/heading';
import type {PortableTextOverrides} from '@/sanity/components/portable-text';

import type {ContainerSpacing} from '../../container';
import {Container} from '../../container';

type Props = PropsWithChildren<{
	heading?: string;
	image?: ImageWeb;
	imageAlignment?: 'left' | 'right';
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
}>;

export const textWithImageSplitPortableTextOverrides: PortableTextOverrides = {};

export const TextWithImageSplit: FC<Props> = ({
	heading,
	image,
	imageAlignment,
	spacingTop,
	spacingBottom,
	children,
}) => {
	return (
		<Container spacingBottom={spacingBottom} spacingTop={spacingTop}>
			<div className={clsx('grid', 'md:grid-cols-2', 'gap-6', 'md:gap-10')}>
				<div
					className={clsx(
						imageAlignment === 'left' ? 'order-0 md:order-1' : 'order-0',
						'md:py-52',
					)}
				>
					{heading && (
						<Heading level={2} spacing className={clsx('mb-6')}>
							{heading}
						</Heading>
					)}
					{children}
				</div>

				<div className={clsx('relative', 'max-md:h-80')}>
					{image && (
						<ImageFill
							src={image.image.src}
							width={image.image.width}
							height={image.image.height}
							hotspot={image.image.hotspot}
							alt={image.altText ?? ''}
						/>
					)}
				</div>
			</div>
		</Container>
	);
};

import {groq} from 'next-sanity';

import type {KeyedArray} from '@/shared/types';

import type {ProductDetails} from '../../shared/types';
import type {ProductInCardFragment} from '../query';
import {PRODUCT_IN_CARD_FRAGMENT, productInCardFragmentToProductDetails} from '../query';

import type {ProductListSelectionSchema} from './schema';

export const PRODUCT_LIST_SELECTION_FRAGMENT = groq`
	...,
	products[]-> {
		isArchived == false => {
			${PRODUCT_IN_CARD_FRAGMENT}
		},
	}
`;

export type ProductListSelectionFragment = Omit<ProductListSelectionSchema, 'products'> & {
	_type: 'productListSelection';
	products?: (ProductInCardFragment | null)[];
};

export const productListFromProductListSelectionSection = (
	section: ProductListSelectionFragment,
): KeyedArray<ProductDetails> => {
	const products: KeyedArray<ProductDetails> = [];

	if (!section.products?.length) {
		return products;
	}

	for (const productInCard of section.products) {
		if (productInCard && productInCard._id) {
			const product = productInCardFragmentToProductDetails(productInCard);
			if (product) {
				products.push({
					_key: productInCard._id,
					...product,
				});
			}
		}
	}

	return products;
};

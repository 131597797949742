import type {FC, PropsWithChildren} from 'react';
import clsx from 'clsx';

import type {ContainerSpacing} from '@/components/container';
import {Container} from '@/components/container';
import type {ImageWeb} from '@/components/image/types';
import {Heading} from '@/components/typography/heading';
import {Paragraph} from '@/components/typography/paragraph';
import type {KeyedArray} from '@/shared/types';

import LinkCard from './LinkCard';

export interface LinkInCard {
	href: string;
	title: string;
	image?: ImageWeb;
	imageAltText?: string;
}

interface Props extends PropsWithChildren {
	heading?: string;
	tagline?: string;
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	links?: KeyedArray<LinkInCard>;
}

const LinkCardGrid: FC<Props> = ({heading, tagline, spacingBottom, spacingTop, links}) => {
	return (
		<Container spacingBottom={spacingBottom} spacingTop={spacingTop}>
			{heading && (
				<Heading level={2} spacing>
					{heading}
				</Heading>
			)}
			{tagline && <Paragraph>{tagline}</Paragraph>}

			<ul
				className={clsx(
					'grid',
					'gap-4',
					'grid-cols-1',
					'md:grid-cols-3',
					'justify-start',
					'content-start',
				)}
			>
				{links?.map((link) => (
					<li key={link._key}>
						<LinkCard title={link.title} href={link.href} image={link.image} />
					</li>
				))}
			</ul>
		</Container>
	);
};

export default LinkCardGrid;

import {groq} from 'next-sanity';

import type {ArticleListAllFragment} from '@/extensions/article/sanity/article-list-all-container/query';
import {ARTICLE_LIST_ALL_FRAGMENT} from '@/extensions/article/sanity/article-list-all-container/query';
import {articleListAll} from '@/extensions/article/sanity/article-list-all-container/schema';
import type {ArticleListSelectionFragmentType} from '@/extensions/article/sanity/article-list-selection-container/query';
import {ARTICLE_LIST_SELECTION_FRAGMENT} from '@/extensions/article/sanity/article-list-selection-container/query';
import {articleListSelection} from '@/extensions/article/sanity/article-list-selection-container/schema';
import type {SanityContactFormSectionSchema} from '@/extensions/contact-form/sanity/contact-form-section-container/contact-form-section';
import type {PersonListAllFragment} from '@/extensions/person/sanity/person-list-all-container/query';
import {PERSON_LIST_ALL_FRAGMENT} from '@/extensions/person/sanity/person-list-all-container/query';
import {personListAll} from '@/extensions/person/sanity/person-list-all-container/schema';
import type {PersonListGroupFragment} from '@/extensions/person/sanity/person-list-group-container/query';
import {PERSON_LIST_GROUP_FRAGMENT} from '@/extensions/person/sanity/person-list-group-container/query';
import {personListGroup} from '@/extensions/person/sanity/person-list-group-container/schema';
import type {PersonListSelectionFragment} from '@/extensions/person/sanity/person-list-selection-container/query';
import {PERSON_LIST_SELECTION_FRAGMENT} from '@/extensions/person/sanity/person-list-selection-container/query';
import {personListSelection} from '@/extensions/person/sanity/person-list-selection-container/schema';
import type {ProductListAllFragment} from '@/extensions/product/sanity/product-list-all-container/query';
import {PRODUCT_LIST_ALL_FRAGMENT} from '@/extensions/product/sanity/product-list-all-container/query';
import {productListAll} from '@/extensions/product/sanity/product-list-all-container/schema';
import type {ProductListSelectionFragment} from '@/extensions/product/sanity/product-list-selection-container/query';
import {PRODUCT_LIST_SELECTION_FRAGMENT} from '@/extensions/product/sanity/product-list-selection-container/query';
import {productListSelection} from '@/extensions/product/sanity/product-list-selection-container/schema';
import type {GallerySchema} from '@/sanity/components/content-blocks/gallery-container/schema';
import type {LinkListSelectionFragmentType} from '@/sanity/components/content-blocks/link-list-selection-container/query';
import {LINK_LIST_SELECTION_FRAGMENT} from '@/sanity/components/content-blocks/link-list-selection-container/query';
import {linkListSelection} from '@/sanity/components/content-blocks/link-list-selection-container/schema';

import type {TextSectionFragmentType} from './text-container/query';
import {TEXT_SECTION_FRAGMENT} from './text-container/query';
import {text} from './text-container/schema';
import type {TextWithImageSplitFragment} from './text-with-image-split-container/query';
import {TEXT_WITH_IMAGE_SPLIT_FRAGMENT} from './text-with-image-split-container/query';
import {textWithImageSplit} from './text-with-image-split-container/schema';

export const CONTENT_BLOCK_FRAGMENT = groq`
	...,

	// Klart foundation
	_type == "${text().name}" => {
		${TEXT_SECTION_FRAGMENT}
	},
	_type == "${textWithImageSplit().name}" => {
		${TEXT_WITH_IMAGE_SPLIT_FRAGMENT}
	},
	_type == "${linkListSelection().name}" => {
		${LINK_LIST_SELECTION_FRAGMENT}
	},

	// Article extension
	_type == "${articleListSelection().name}" => {
		${ARTICLE_LIST_SELECTION_FRAGMENT}
	},
	_type == "${articleListAll().name}" => {
		${ARTICLE_LIST_ALL_FRAGMENT}
	},

	// Person extension
	_type == "${personListSelection().name}" => {
		${PERSON_LIST_SELECTION_FRAGMENT}
	},
	_type == "${personListAll().name}" => {
		${PERSON_LIST_ALL_FRAGMENT}
	},
	_type == "${personListGroup().name}" => {
		${PERSON_LIST_GROUP_FRAGMENT}
	},

	// Product extension
	_type == "${productListSelection().name}" => {
		${PRODUCT_LIST_SELECTION_FRAGMENT}
	},
	_type == "${productListAll().name}" => {
		${PRODUCT_LIST_ALL_FRAGMENT}
	}
`;

export type ContentBlockFragment =
	| TextSectionFragmentType
	| TextWithImageSplitFragment
	| GallerySchema
	| LinkListSelectionFragmentType
	| ArticleListSelectionFragmentType
	| ArticleListAllFragment
	| PersonListSelectionFragment
	| PersonListAllFragment
	| PersonListGroupFragment
	| SanityContactFormSectionSchema
	| ProductListAllFragment
	| ProductListSelectionFragment;

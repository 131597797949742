import type {FC} from 'react';

import ProductList from '../../components/product-list';

import {
	productListFromProductListSelectionSection,
	type ProductListSelectionFragment,
} from './query';

interface Props {
	data: ProductListSelectionFragment;
}

export const ProductListSelectionContainer: FC<Props> = ({data}) => {
	const products = productListFromProductListSelectionSection(data);

	return (
		<ProductList
			title={data.title}
			tagline={data.tagline}
			products={products}
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
		/>
	);
};

import {toPlainText} from '@portabletext/react';
import {groq} from 'next-sanity';

import {imageWebFromImageWebSchema} from '@/sanity/components/image/query';
import {internalLinkToHref} from '@/shared';

import type {ProductDetails} from '../shared/types';

import type {ProductSchema} from './schema';

export const PRODUCT_IN_CARD_FRAGMENT = groq`
	_id,
	title,
	slug,
	description,
	price,
	image,
	priceId
`;

export type ProductInCardFragment = Pick<
	ProductSchema,
	'_id' | 'title' | 'slug' | 'description' | 'image' | 'price' | 'priceId'
>;

export function productInCardFragmentToProductDetails(
	product: ProductInCardFragment,
): ProductDetails {
	return {
		title: product.title ?? '',
		description: product.description ? toPlainText(product.description) : '',
		href: internalLinkToHref('product', product.slug?.current),
		price: product.price,
		priceId: product.priceId,
		imageWeb: product?.image?.asset
			? imageWebFromImageWebSchema(product.image, 4 / 4)
			: undefined,
	};
}

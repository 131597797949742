import type {FC} from 'react';
import clsx from 'clsx';

import type {ContainerSpacing} from '@/components/container';
import {Container} from '@/components/container';
import {Heading} from '@/components/typography/heading';
import {Paragraph} from '@/components/typography/paragraph';
import type {KeyedArray} from '@/shared/types';

import type {Article} from '../../shared/types';
import {ArticleCard} from '../article-card';

interface Props {
	title?: string;
	tagline?: string;
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	articles: KeyedArray<Article>;
}

const ArticleList: FC<Props> = ({title, tagline, spacingBottom, spacingTop, articles}) => {
	return (
		<Container spacingBottom={spacingBottom} spacingTop={spacingTop}>
			{title && (
				<Heading level={2} spacing>
					{title}
				</Heading>
			)}
			{tagline && <Paragraph>{tagline}</Paragraph>}

			<ul className={clsx('grid', 'auto-rows-fr', 'grid-cols-1', 'lg:grid-cols-3')}>
				{articles && (
					<>
						{articles.map((article) => {
							return (
								<li key={article._key}>
									<ArticleCard article={article} headingLevel={title ? 3 : 2} />
								</li>
							);
						})}
					</>
				)}
			</ul>
		</Container>
	);
};
export default ArticleList;

import React from 'react';

type ResponseMessageProps = {
	success: boolean;
	errorMessage: string | null;
	successMessage: string | null;
};

export const ResponseMessage = ({success, errorMessage, successMessage}: ResponseMessageProps) => {
	return (
		<>
			{success ? (
				<p className="text-green-600" role="status">
					{successMessage}
				</p>
			) : (
				<p className="text-red-600" role="status">
					{errorMessage}
				</p>
			)}
		</>
	);
};
